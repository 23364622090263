<template>
  <div class="pdf-container">
    <object
      v-if="pdfUrl"
      ref="pdfObject"
      :data="modifiedPdfUrl"
      type="application/pdf"
      class="pdf-object"
      width="100%"
      height="100%"
      :style="{ height: pdfHeight }"
    >
      <p>
        Unable to display PDF.
        <a :href="pdfUrl" target="_blank">Download PDF</a>.
      </p>
    </object>
  </div>
</template>

<script>
import { PDFDocument } from 'pdf-lib'

export default {
  props: {
    file: {
      type: [File, Object],
      default: null,
    },
    zoom: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      pdfUrl: null, // Original PDF URL
      modifiedPdfUrl: null, // URL with query parameters
      pdfHeight: '600px', // Default height; can be adjusted
    }
  },
  watch: {
    file: {
      immediate: true,
      async handler(newFile) {
        if (
          (newFile instanceof Blob || newFile instanceof File) &&
          newFile.type === 'application/pdf'
        ) {
          // Create a URL for the file
          this.pdfUrl = URL.createObjectURL(newFile)
          // Modify the URL with zoom and toolbar parameters
          this.modifiedPdfUrl =
            this.pdfUrl + `#zoom=60&toolbar=0&navpanes=0&scrollbar=0`

          // Adjust the height based on the PDF dimensions
          await this.setPdfHeight(newFile)
        } else if (newFile instanceof Object) {
          this.pdfUrl = newFile.full_url
          this.modifiedPdfUrl =
            this.pdfUrl + `#zoom=${this.zoom}&toolbar=0&navpanes=0&scrollbar=0`

          const blob = await this.fetchPdfAsBlob()

          if (blob) {
            const file = new File([blob], 'pdf.pdf')

            await this.setPdfHeight(file)
          }
        } else {
          this.pdfUrl = null // Clear URL if the file is not a PDF
        }
      },
    },
  },
  methods: {
    async setPdfHeight(file) {
      try {
        if (!(file instanceof File)) return

        // Read the PDF file
        const arrayBuffer = await file.arrayBuffer()
        const pdfDoc = await PDFDocument.load(arrayBuffer)
        const [page] = pdfDoc.getPages() // Get the first page
        const { width, height } = page.getSize() // Get width and height of the first page

        // Set the height based on the current width of the container
        this.$nextTick(() => {
          const objectElement = this.$refs.pdfObject
          const containerElement = this.$refs.pdfContainer // Add reference to container

          if (objectElement && containerElement) {
            const objectWidth = objectElement.clientWidth
            const containerHeight = containerElement.clientHeight

            // Calculate the height based on aspect ratio
            let pdfHeight = objectWidth * (height / width)

            // Ensure pdfHeight does not exceed container height
            if (pdfHeight > containerHeight) {
              pdfHeight = containerHeight // Limit to container height
            }

            // Set the final PDF height
            this.pdfHeight = `${pdfHeight}px`

            // Calculate the zoom factor to fit the width
            const zoomFactor = (objectWidth / width) * 100
            this.modifiedPdfUrl = `${this.pdfUrl}#zoom=${zoomFactor}&toolbar=0&navpanes=0&scrollbar=0`
          }
        })
      } catch (error) {
        console.error('Error setting PDF height:', error)
      }
    },

    async fetchPdfAsBlob() {
      try {
        const response = await fetch(this.pdfUrl)

        if (!response.ok) {
          return null
        }

        return await response.blob()
      } catch (error) {
        return null
      }
    },
  },
  mounted() {
    // If file prop is already provided when component mounts
    if (this.file) {
      this.setPdfHeight(this.file)
    }
  },
}
</script>

<style scoped>
.pdf-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pdf-object {
  display: block;
  margin: 0;
  padding: 0;
  border: none; /* Remove default border if any */
  width: 100%;
}
</style>
